import { defineStore } from 'pinia';
import { post } from '../helpers/main.js';

export default defineStore('articleCollect', {
	state: () => ({}),
	actions: {
		async update(q) {
			try {
				const response = await post('/article_collect/update', q);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},
	},
});
