import is from './is';
import gender from './gender';
import pageSize from './pageSize';

const CONSTANTS = {
	is,
	gender,
	pageSize,
};

export default CONSTANTS;
