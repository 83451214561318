import { defineStore } from 'pinia';
import { post } from '../helpers/main.js';

export default defineStore('selfMe', {
	state: () => ({}),
	actions: {
		async getItem(q) {
			try {
				const response = await post('/self/me/get_item', { id: q });
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},

		async update(q) {
			try {
				const response = await post('/self/me/update', q);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},

		async updateNotification(q) {
			try {
				const response = await post('/self/me/update_notification', q);
				return response.data;
			} catch (error) {
				console.error('Failed to update', error);
			}
		},
	},
});
