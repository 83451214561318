import { createPinia } from 'pinia';

import useArticleStore from './article';
import useArticleCollectStore from './articleCollect';
import useArticleCommentStore from './articleComment';
import useArticleLikeStore from './articleLike';
import useNotificationStore from './notification';
import usePhotoStore from './photo';
import useTagStore from './tag';
import useUserStore from './user';
import useUserRelationStore from './userRelation';
import useArticleTypeStore from './articleType';
import useHelperStore from './helper';
import useHomeStore from './home';
import useExploreStore from './explore';
import useArticleCommentLikeStore from './articleCommentLike';
import useArticleTopicStore from './articleTopic';
import useUserTagStore from './userTag';
import useAuthStore from './auth';

// self
import useSelfMeStore from './selfMe';
import useSelfUserTagStore from './selfUserTag';
import useSelfArticleCollectStore from './selfArticleCollect';
import useSelfBlockStore from './selfBlock';
import useSelfArticleStore from './selfArticle';
import useSelfAnalyticsStore from './selfAnalytics';

const pinia = createPinia();

export {
	pinia,
	useArticleStore,
	useArticleCollectStore,
	useArticleCommentStore,
	useArticleLikeStore,
	useNotificationStore,
	usePhotoStore,
	useTagStore,
	useUserStore,
	useUserRelationStore,
	useArticleTypeStore,
	useHelperStore,
	useHomeStore,
	useExploreStore,
	useArticleCommentLikeStore,
	useArticleTopicStore,
	useUserTagStore,
	useAuthStore,

	// self
	useSelfMeStore,
	useSelfUserTagStore,
	useSelfArticleCollectStore,
	useSelfBlockStore,
	useSelfArticleStore,
	useSelfAnalyticsStore,
};
