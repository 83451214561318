import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import { useStore } from '../store/main';

const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
		meta: {
			indexTab: 1,
		},
	},

	{
		path: '/login',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue'),
	},

	{
		path: '/login/email',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/email.vue'),
	},

	{
		path: '/explore/index',
		component: () => import(/* webpackChunkName: "explore" */ '../views/explore/index.vue'),
		meta: {
			indexTab: 2,
		},
	},

	{
		path: '/article/item/:id(\\d+)/:name?',
		component: () => import(/* webpackChunkName: "articleItem" */ '../views/article/item.vue'),
	},

	{
		path: '/article/list',
		component: () => import(/* webpackChunkName: "articleList" */ '../views/article/list.vue'),
	},

	{
		path: '/article/update',
		component: () => import(/* webpackChunkName: "articleUpdate" */ '../views/article/update.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		path: '/search/list',
		component: () => import(/* webpackChunkName: "searchList" */ '../views/search/list.vue'),
	},

	{
		path: '/user/list',
		component: () => import(/* webpackChunkName: "userList" */ '../views/user/list.vue'),
	},

	{
		path: '/notification/list',
		component: () => import(/* webpackChunkName: "notificationList" */ '../views/notification/list.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		path: '/user/item/:id(\\d+)/:name?',
		component: () => import(/* webpackChunkName: "userItem" */ '../views/user/item.vue'),
	},

	{
		path: '/version',
		component: () => import(/* webpackChunkName: "version" */ '../views/version.vue'),
	},

	/*
	..######..########.##.......########
	.##....##.##.......##.......##......
	.##.......##.......##.......##......
	..######..######...##.......######..
	.......##.##.......##.......##......
	.##....##.##.......##.......##......
	..######..########.########.##......
	*/

	{
		path: '/self/article',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/article.vue'),
		meta: {
			isLogin: true,
		},
	},

	// {
	// 	path: '/self/analytics',
	// 	component: () => import(/* webpackChunkName: "self" */ '../views/self/analytics.vue'),
	// 	meta: {
	// 		isLogin: true,
	// 	},
	// },

	// {
	// 	path: '/self/block',
	// 	component: () => import(/* webpackChunkName: "self" */ '../views/self/block.vue'),
	// 	meta: {
	// 		isLogin: true,
	// 	},
	// },
	{
		path: '/self/collect',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/collect.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		path: '/self/me',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/me.vue'),
		meta: {
			isLogin: true,
		},
	},

	// {
	// 	path: '/self/tag',
	// 	component: () => import(/* webpackChunkName: "self" */ '../views/self/tag.vue'),
	// 	meta: {
	// 		isLogin: true,
	// 	},
	// },

	// {
	// 	path: '/self/milestone',
	// 	component: () => import(/* webpackChunkName: "self" */ '../views/self/milestone.vue'),
	// 	meta: {
	// 		isLogin: true,
	// 	},
	// },

	{
		path: '/self/notification',
		component: () => import(/* webpackChunkName: "self" */ '../views/self/notification.vue'),
		meta: {
			isLogin: true,
		},
	},

	/*
	..#######..########.##.....##.########.########.
	.##.....##....##....##.....##.##.......##.....##
	.##.....##....##....##.....##.##.......##.....##
	.##.....##....##....#########.######...########.
	.##.....##....##....##.....##.##.......##...##..
	.##.....##....##....##.....##.##.......##....##.
	..#######.....##....##.....##.########.##.....##
	*/
	{
		path: '/setToken',
		component: () => import(/* webpackChunkName: "site" */ '../views/setToken.vue'),
	},

	// {
	// 	path: '/testLogin',
	// 	component: () => import(/* webpackChunkName: "test" */ '../views/testLogin.vue'),
	// },

	/*
	.##..........#####...##.......
	.##....##...##...##..##....##.
	.##....##..##.....##.##....##.
	.##....##..##.....##.##....##.
	.#########.##.....##.#########
	.......##...##...##........##.
	.......##....#####.........##.
	*/
	{
		path: '/:pathMatch(.*)*',
		component: () => import(/* webpackChunkName: "site" */ '../views/notFound.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 };
	},
});

async function checkUser() {
	let urlRedirect = null;
	if (!window.isStatusChecked) {
		const token = localStorage.getItem('token');
		if (token) {
			const config = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};
			try {
				let response = await axios.post(process.env.API_URL + '/v1/login/token', {}, config);

				const store = useStore();

				store.setUser({
					id: response.data.user.id,
					name: response.data.user.name,
					email: response.data.user.email,
					photo: response.data.user.photo,
				});
			} catch (e) {
				// remove token
				localStorage.removeItem('token');
			}
		}
	}
	window.isStatusChecked = true;
	return urlRedirect;
}

router.beforeEach(async (to, from, next) => {
	const urlRedirect = await checkUser();
	if (to.meta.isLogin) {
		if (window.isLogin) {
			if (urlRedirect) {
				next(urlRedirect);
			} else {
				next();
			}
		} else {
			next('/login');
		}
	} else {
		next();
	}
});

export default router;
