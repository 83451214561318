import { defineStore } from 'pinia';
import { post } from '../helpers/main.js';

export default defineStore('userTag', {
	state: () => ({}),
	actions: {
		async getItems(condition = {}) {
			let baseCondition = {
				_pageSize: 100,
				_page: 1,
				_orderField: 'priority',
				_orderDirection: 'desc',
				tag: '',
			};

			condition = { ...baseCondition, ...condition };
			try {
				const response = await post('/user_tag/get_list', condition);
				return response.data;
			} catch (error) {
				console.error('Failed to fetch items', error);
			}
		},
	},
});
