import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n.js';

import { pinia } from './store/index.js';

// custom css
import './style/_index.css';

import constants from './constants/_index.js';

import router from './router/index.js';

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { vOnClickOutside } from '@vueuse/components';

const app = createApp(App);

import { createHead } from '@unhead/vue';
const head = createHead();

import { useHead } from '@vueuse/head';

/*
..######...##........#######..########.....###....##..........##.....##....###....########..####....###....########..##.......########
.##....##..##.......##.....##.##.....##...##.##...##..........##.....##...##.##...##.....##..##....##.##...##.....##.##.......##......
.##........##.......##.....##.##.....##..##...##..##..........##.....##..##...##..##.....##..##...##...##..##.....##.##.......##......
.##...####.##.......##.....##.########..##.....##.##..........##.....##.##.....##.########...##..##.....##.########..##.......######..
.##....##..##.......##.....##.##.....##.#########.##...........##...##..#########.##...##....##..#########.##.....##.##.......##......
.##....##..##.......##.....##.##.....##.##.....##.##............##.##...##.....##.##....##...##..##.....##.##.....##.##.......##......
..######...########..#######..########..##.....##.########.......###....##.....##.##.....##.####.##.....##.########..########.########
*/
app.config.globalProperties.isProduction = process.env.NODE_ENV === 'production';
app.config.globalProperties.isDev = process.env.NODE_ENV !== 'production';
app.config.globalProperties.option = constants;

/*
..######...##........#######..########.....###....##..........########.##.....##.##....##..######..########.####..#######..##....##
.##....##..##.......##.....##.##.....##...##.##...##..........##.......##.....##.###...##.##....##....##.....##..##.....##.###...##
.##........##.......##.....##.##.....##..##...##..##..........##.......##.....##.####..##.##..........##.....##..##.....##.####..##
.##...####.##.......##.....##.########..##.....##.##..........######...##.....##.##.##.##.##..........##.....##..##.....##.##.##.##
.##....##..##.......##.....##.##.....##.#########.##..........##.......##.....##.##..####.##..........##.....##..##.....##.##..####
.##....##..##.......##.....##.##.....##.##.....##.##..........##.......##.....##.##...###.##....##....##.....##..##.....##.##...###
..######...########..#######..########..##.....##.########....##........#######..##....##..######.....##....####..#######..##....##
*/
// global use store, for speed up develop, remove this after finished this project

app.config.globalProperties.alert = function (q) {
	window.alert(q);
};

app.config.globalProperties.toast = function (message, type = 'success') {
	this.$toast.open({
		message: message,
		type: type,
		position: 'top-right',
		// duration: 10000000,
	});
};

app.config.globalProperties.toDate = function (q) {
	let text = '--';
	try {
		const dateObj = new Date(q);
		const year = dateObj.getFullYear();
		const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Months are zero-indexed
		const day = ('0' + dateObj.getDate()).slice(-2);
		text = `${year}/${month}/${day}`;
	} catch (e) {}
	return text;
};

app.config.globalProperties.typeText = function (items, id, defaultText = '--', key = 'id') {
	try {
		let temp = null;
		temp = items.find((z) => z[key] === id);
		if (temp) {
			defaultText = temp.name;
		}
	} catch (e) {}
	return defaultText;
};

app.config.globalProperties.asset = function (q, defaultImage = '_image.png') {
	if (q === null || q === '') {
		return '/img/' + defaultImage;
	} else {
		return process.env.VUE_APP_ASSET_URL + q;
	}
};

app.config.globalProperties.changePhoto = function (variable, key, accept = 'image/png, image/jpeg') {
	const uploadFileDo = (variable, key, file) => {
		const self = this;
		let formData = new FormData();
		formData.append('files', file, file.name);
		let xhr = new XMLHttpRequest();
		const token = localStorage.getItem('token');
		xhr.open('POST', process.env.API_URL + '/v1/_helper/upload_files_google', true);
		xhr.setRequestHeader('authorization', `Bearer ${token}`);

		xhr.onload = function () {
			if (xhr.status === 200) {
				let json = JSON.parse(xhr.response);
				variable[key] = json.files[0].base_name;
			} else {
				self.alert('Upload failed.');
			}
		};
		xhr.onerror = function () {
			self.alert('Upload failed.');
		};
		xhr.send(formData);
	};

	let fileInput = this.$el.querySelector('.file');
	fileInput = document.createElement('input');
	fileInput.setAttribute('type', 'file');
	fileInput.setAttribute('accept', accept);
	// fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon, image/svg+xml');
	fileInput.classList.add('hidden');
	fileInput.addEventListener('change', () => {
		if (fileInput.files != null && fileInput.files[0] != null) {
			// check file size first
			// var fileSize = fileInput.files[0].size;
			// if (fileSize / 1024 > maxSize * 1024) {
			// 	self.alert("File size can't above " + maxSize + 'MB');
			// 	return;
			// }

			const file = fileInput.files[0];

			uploadFileDo(variable, key, file);
		} else {
		}
	});
	this.$el.appendChild(fileInput);
	fileInput.click();
};

// app.config.globalProperties.getTimeDiffer = function (q) {
// 	let returnText = '--';
// 	try {
// 		const date = new Date(q);

// 		// Calculate the difference in milliseconds from now
// 		const now = new Date();
// 		const diffMs = now - date;
// 		const diffMins = Math.round(diffMs / 60000);

// 		// Determine the appropriate time unit to display
// 		if (diffMins < 60) {
// 			returnText = `${diffMins}分鐘前`; // minutes ago
// 		} else if (diffMins < 1440) {
// 			// 1440 minutes in a day
// 			const diffHours = Math.floor(diffMins / 60);
// 			returnText = `${diffHours}小時前`; // hours ago
// 		} else {
// 			const diffDays = Math.floor(diffMins / 1440);
// 			returnText = `${diffDays}天前`; // days ago
// 		}
// 	} catch (e) {
// 		// Handle potential errors
// 	}
// 	return returnText;
// };

app.config.globalProperties.getTimeDiffer = function (q) {
	let returnText = '--';
	try {
		// Parse the date, ensuring it considers time zone (assuming it's UTC)
		const date = new Date(q + 'Z'); // Add 'Z' if the date from server is UTC without timezone

		// Calculate the difference in milliseconds from now
		const now = new Date();
		const diffMs = now - date;
		const diffMins = Math.round(diffMs / 60000);

		// Determine the appropriate time unit to display
		if (diffMins < 60) {
			returnText = `${diffMins}分鐘前`; // minutes ago
		} else if (diffMins < 1440) {
			const diffHours = Math.floor(diffMins / 60);
			returnText = `${diffHours}小時前`; // hours ago
		} else {
			const diffDays = Math.floor(diffMins / 1440);
			returnText = `${diffDays}天前`; // days ago
		}
	} catch (e) {
		// Handle potential errors
	}
	return returnText;
};

app.config.globalProperties.setMeta = function (title = null, description = null, image = null) {
	if (title == null) {
		title = '微格Wlog';
	} else {
		title = title + ' - 微格Wlog';
	}

	if (description == null) {
	}

	if (image == null) {
		image = '/img/logo.png';
	} else {
		image = this.asset(image);
	}

	useHead({
		title: title,
		meta: [
			{ name: 'description', content: description },
			{ property: 'og:description', content: description },
			{ property: 'og:image', content: image },
		],
	});
};

app.use(pinia);

app.directive('on-click-outside', vOnClickOutside);

// global use store, for speed up develop, remove this after finished this project
import {
	useTagStore,
	useArticleStore,
	useUserStore,
	useArticleCollectStore,
	useArticleCommentStore,
	useArticleLikeStore,
	useNotificationStore,
	usePhotoStore,
	useUserRelationStore,
	useArticleTypeStore,
	useHomeStore,
	useExploreStore,
	useHelperStore,
	useArticleCommentLikeStore,
	useAuthStore,
	// self ------------------------
	useSelfMeStore,
	useSelfUserTagStore,
	useSelfArticleCollectStore,
	useSelfBlockStore,
	useSelfArticleStore,
	useArticleTopicStore,
	useSelfAnalyticsStore,
	useUserTagStore,
} from './store/index.js';

app.config.globalProperties.useArticle = useArticleStore();
app.config.globalProperties.useUserRelation = useUserRelationStore();
app.config.globalProperties.useTag = useTagStore();
app.config.globalProperties.useUser = useUserStore();
app.config.globalProperties.useArticleCollect = useArticleCollectStore();
app.config.globalProperties.useArticleComment = useArticleCommentStore();
app.config.globalProperties.useArticleLike = useArticleLikeStore();
app.config.globalProperties.useNotification = useNotificationStore();
app.config.globalProperties.usePhoto = usePhotoStore();
app.config.globalProperties.useArticleType = useArticleTypeStore();
app.config.globalProperties.useHome = useHomeStore();
app.config.globalProperties.useExplore = useExploreStore();
app.config.globalProperties.useHelper = useHelperStore();
app.config.globalProperties.useArticleCommentLike = useArticleCommentLikeStore();
app.config.globalProperties.useArticleTopic = useArticleTopicStore();
app.config.globalProperties.useUserTag = useUserTagStore();
app.config.globalProperties.useAuth = useAuthStore();

// self
app.config.globalProperties.useSelfMe = useSelfMeStore();
app.config.globalProperties.useSelfUserTag = useSelfUserTagStore();
app.config.globalProperties.useSelfArticleCollect = useSelfArticleCollectStore();
app.config.globalProperties.useSelfBlock = useSelfBlockStore();
app.config.globalProperties.useSelfArticle = useSelfArticleStore();
app.config.globalProperties.useSelfAnalytics = useSelfAnalyticsStore();

import { useStore } from './store/main.js';

app.config.globalProperties.store = useStore();

app.use(router);
app.use(i18n);
app.use(head);

app.use(ToastPlugin);

app.component('VueDatePicker', VueDatePicker);

app.mount('#app');
