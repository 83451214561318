import axios from 'axios';

// async function postAsync(url, data) {
// 	const config = {};
// 	const token = localStorage.getItem('token');
// 	if (token) {
// 		config.headers = {
// 			authorization: `Bearer ${token}`,
// 		};
// 	}
// 	try {
// 		const result = await axios.post(process.env.API_URL + url, data, config);
// 		if (result.data.isSuccess == false) {
// 			alert(result.data.message);
// 			return false;
// 		} else {
// 			return result.data;
// 		}
// 	} catch (e) {
// 		return e.response.data;
// 	}
// }

function post(url, data, prefix = '/v1') {
	const config = {};
	const token = localStorage.getItem('token');
	if (token) {
		config.headers = {
			authorization: `Bearer ${token}`,
		};
	}
	return axios.post(process.env.API_URL + prefix + url, data, config);
}

async function getType(q, variable = {}, callback = null) {
	const self = this;
	switch (q) {
		case 'user':
			variable = {
				_orderDirection: 'desc',
				_orderField: 'id',
				_page: 1,
				_pageSize: 10,
			};
			this.post('/user/getListing', variable).then(function (response) {
				self.option[q] = response.data.data;
				if (callback != null) {
					callback(response);
				}
			});
			break;
	}
}

function isInclude(items, value) {
	return items.includes(value);
}

function getDate(date = new Date()) {
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (month >= 1 && month <= 9) {
		month = '0' + month;
	}
	if (day >= 0 && day <= 9) {
		day = '0' + day;
	}
	const currentDate = year + '-' + month + '-' + day;
	return currentDate;
}

function isPermission(q, module = null) {
	if (module == null) {
		module = this.moduleName;
	}
	const permissionID = module + '-' + q;
	if (window.user) {
		const temp = window.user.permissions.find((z) => z == permissionID);
		return temp;
	}
	return null;
}

export { post, isInclude, getDate, isPermission, getType };
